* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family-sans);
  font-size: var(--m);
  color: var(--text-secondary);
  height: 100%;
  line-height: 1.4;
}
#root {
  height: 100%;
}

h1, h2, h3, h4 {
  margin: 0 0 .5rem;
  color: var(--text-primary);
}

h1 {
  font-size: var(--xxl);
  font-weight: 700;
  margin: 0;
  font-family: var(--font-family-sans);
  line-height: 1.1;
}
h2 {
  font-size: var(--xl);
  font-weight: 700;
  line-height: 1.1;
  margin: 4rem 0 1.5rem;
}
h3 {
  font-size: var(--m);
  font-weight: 600;
  line-height: 1.1;
}
h4 {
  font-size: var(--s);
  text-transform: uppercase;
  letter-spacing: 0.03rem;
  color: var(--text-secondary);
  font-weight: 500;
  margin: 0 0 .25rem;
}

ul,
ol {
  padding: 0 0 0 1rem;
}

ul li,
ol li {
  margin: 0 0 .5rem;
}

p {
  margin: 0 0 .5rem;
  color: var(--text-secondary);
}

a {
  text-decoration: none;
  color: inherit;
  outline: none;
}

hr {
  height: 1px;
  background: var(--hairline);
  border: none;
  margin: 1rem 0;
}

::placeholder {
  color: var(--text-tertiary);
}

strong {
  color: var(--text-primary);
}

/* button spinner */
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
