:root {

  /* TYPE */
  --font-family-sans: 'Inter', -apple-system, BlinkMacSystemFont, helvetica, arial, sans-serif;
  --font-family-display: 'Passion One', Impact, 'Inter', -apple-system, BlinkMacSystemFont, helvetica, arial, sans-serif;

  --xxl: 1.8rem;
  --xl: 1.4rem;
  --l: 1.15rem;
  --m: 1rem;
  --s: .9rem;
  --xs: .8rem;


  /* PALETTE */

  /* gray */
  --gray-10: hsl(230, 10%, 10%);
  --gray-20: hsl(230, 10%, 20%);
  --gray-30: hsl(230, 10%, 30%);
  --gray-40: hsl(230, 10%, 40%);
  --gray-50: hsl(230, 10%, 50%);
  --gray-60: hsl(230, 10%, 60%);
  --gray-70: hsl(230, 10%, 70%);
  --gray-80: hsl(230, 10%, 80%);
  --gray-90: hsl(230, 10%, 90%);
  --gray-92: hsl(230, 10%, 92%);
  --gray-94: hsl(230, 10%, 94%);
  --gray-96: hsl(230, 10%, 96%);
  --gray-98: hsl(230, 10%, 98%);

  /* roy g biv */
  --red-50: hsl(0, 80%, 60%);
  --red-60: hsl(0, 80%, 65%);
  --red-96: hsl(0, 80%, 96%);

  --yellow-50: hsl(55, 100%, 60%);
  --yellow-60: hsl(55, 100%, 65%);
  --yellow-90: hsl(55, 100%, 90%);
  --yellow-96: hsl(55, 100%, 96%);

  --green-50: hsl(140, 60%, 40%);
  --green-60: hsl(140, 60%, 45%);
  --green-70: hsl(140, 60%, 50%);
  --green-80: hsl(140, 60%, 75%);
  --green-90: hsl(140, 60%, 85%);
  --green-96: hsl(140, 60%, 94%);
  
  --blue-40: hsl(230, 90%, 40%);
  --blue-50: hsl(230, 90%, 50%);
  --blue-55: hsl(230, 90%, 55%);
  --blue-60: hsl(230, 90%, 60%);
  --blue-65: hsl(230, 90%, 65%);
  --blue-70: hsl(230, 90%, 70%);
  --blue-75: hsl(230, 90%, 75%);
  --blue-80: hsl(230, 90%, 80%);
  --blue-85: hsl(230, 90%, 85%);
  --blue-90: hsl(230, 90%, 90%);
  --blue-96: hsl(230, 90%, 96%);
  --blue-98: hsl(230, 90%, 98%);
  
  /* SEMANTIC COLORS */

  /* text */
  --text-primary: var(--gray-30);
  --text-secondary: var(--gray-50);
  --text-tertiary: var(--gray-70);
  
  /* hairline */
  --hairline: var(--gray-92);
  --hairline-dark: var(--gray-80);
  --hairline-darker: var(--gray-70);
  
  /* shadow */
  --shadow: hsla(40, 10%, 60%, .1); /* TODO - transparent gray color defs */
  --shadow-overlay: hsla(230, 10%, 30%, .2); /* TODO - transparent gray color defs */
  
  /* hover */
  --hover: var(--gray-96);
  --hover-light: var(--gray-98);
  
  /* backgrounds */
  --background-item: var(--gray-96);
  --background-page: var(--gray-98);
  --modal-background: hsla(230, 10%, 60%, .5);
  
  /* brand colors */
  --brand-primary: var(--gray-20);
  --link: var(--blue-65);
  
  /* misc */
  --destructive: var(--red-50);
}
