.ProseMirror {
  outline: none;
  padding: 1.5rem 2rem;
  min-height: 16rem;
}

.ProseMirror[contenteditable="false"] {
  padding: 0;
}

.ProseMirror > * + * {
  margin-top: 0.75em;
}

.ProseMirror p,
.ProseMirror ul,
.ProseMirror ol {
  color: var(--text-primary);
  font-size: var(--m);
}

.ProseMirror ul,
.ProseMirror ol {
  padding: 0 0 0 2rem;
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  font-weight: 700;
  margin: 1.5rem 0 .5rem;
  line-height: 1.1;
}
.ProseMirror h2 {
  font-weight: 800;
  font-size: var(--xl);
  margin: 3rem 0 1rem;
}
.ProseMirror h3 {
  font-weight: 700;
  font-size: var(--l);
  margin: 2rem 0 1rem;
}
.ProseMirror a {
  color: var(--link);
  text-decoration: underline;
}

/* .ProseMirror code {
  background-color: rgba(#616161, 0.1);
  color: #616161;
}

.ProseMirror pre {
  background: #0D0D0D;
  color: #FFF;
  font-family: 'JetBrainsMono', monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;

}
.ProseMirror pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
} */

.ProseMirror img {
  max-width: 100%;
  height: auto;
}

.ProseMirror img.ProseMirror-selectednode {
  outline: 3px solid var(--link);
}

.ProseMirror strong {
  font-weight: 600;
  color: var(--brand-primary);
}

.ProseMirror blockquote {
  padding-left: 1rem;
  border-left: 2px solid var(--hairline);
  margin-left: 0;
}
.ProseMirror blockquote p,
.ProseMirror blockquote li {
  font-size: var(--l);
  font-style: italic;
  color: var(--text-secondary);
}

.ProseMirror hr {
  border: none;
  border-top: 2px solid var(--hairline);
  margin: 2rem 0;
}
